.loading-container {
  position: absolute;
  background-color: rgba(165, 165, 165, 0.4);
  top: 70px;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  z-index: 100 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-in-component {
  top: 0;
}