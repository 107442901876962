.app-card-header {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #ced4da;
  font-size: 16px;
  font-size: 600;
  color: #252525;
  span {
    padding: 0 5px;
    font-size: 18px;
  }
}
