.app-button {
  min-width: 80px !important;
  max-width: 250px !important;
  border-radius: 5px;

  span {
    font-size: 12px;
  }
  &.large {
    height: 40px;
    span {
      font-size: 13px;
    }
  }
  &.medium {
    height: 30px;
  }
  &.small {
    height: 22px;
  }
}
