.label-value {
  display: flex;
  align-items: center;
  margin: 10px 14px;
  overflow: hidden;

  .text-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }

  .value {
    font-weight: 600;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;

  }

  .label {
    font-size: 12px;
    margin-right: 5px;
  }

  i {
    font-size: 20px;
    margin-right: 10px;
    color: $app-primary;
  }
}